<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Product</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="product-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Tagline *"
            v-model="fields.tagline"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tagline')"
            :error-messages="errors['tagline']"
          ></v-text-field>
          <v-textarea
            label="Summary"
            v-model="fields.summary"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('summary')"
            :error-messages="errors['summary']"
          ></v-textarea>
          <v-file-input
            v-model="fields.image"
            accept="image/png, image/jpeg"
            placeholder="Choose an Image"
            prepend-icon="mdi-camera"
            label="Image *"
            outlined
            background-color="white"
            @change="imageHasChanged()"
            :error="errors.hasOwnProperty('image')"
            :error-messages="errors['image']"
          ></v-file-input>
          <v-text-field
            label="GA Reference Code *"
            v-model="fields.ga_code"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('ga_code')"
            :error-messages="errors['ga_code']"
          ></v-text-field>
          <v-switch
            label="Out of stock"
            v-model="fields.out_of_stock"
            color="green"
            class="mt-1 mb-4"
            inset
            :error="errors.hasOwnProperty('out_of_stock')"
            :error-messages="errors['out_of_stock']"
          ></v-switch>

          <v-card elevation="2" class="mb-8">
            <v-card-subtitle>Prices</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
              <v-text-field
                label="2kg Bag Price"
                v-model="fields._2_bag_price"
                type="text"
                outlined
                prefix="£"
                :error="errors.hasOwnProperty('_2_bag_price')"
                :error-messages="errors['_2_bag_price']"
              ></v-text-field>
              <v-text-field
                label="12kg Bag Price *"
                v-model="fields._12_bag_price"
                type="text"
                outlined
                prefix="£"
                :error="errors.hasOwnProperty('_12_bag_price')"
                :error-messages="errors['_12_bag_price']"
              ></v-text-field>
              <v-text-field
                label="24kg Bag Price *"
                v-model="fields._24_bag_price"
                type="text"
                outlined
                prefix="£"
                :error="errors.hasOwnProperty('_24_bag_price')"
                :error-messages="errors['_24_bag_price']"
              ></v-text-field>
            </v-card-text>
          </v-card>
          <v-switch
            label="Is this an Adult Recipe?"
            v-model="fields.is_adult"
            color="green"
            class="mt-1 mb-4"
            inset
            :error="errors.hasOwnProperty('is_adult')"
            :error-messages="errors['is_adult']"
          ></v-switch>
          <v-switch
            label="Is this an Puppy Recipe?"
            v-model="fields.is_puppy"
            color="green"
            class="mt-1 mb-4"
            inset
            :error="errors.hasOwnProperty('is_puppy')"
            :error-messages="errors['is_puppy']"
          ></v-switch>
          <v-textarea
            label="Composition *"
            v-model="fields.composition_text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('composition_text')"
            :error-messages="errors['composition_text']"
          ></v-textarea>
          <v-file-input
            v-model="fields.composition_image"
            accept="image/png, image/jpeg"
            placeholder="Choose a Composition Image"
            prepend-icon="mdi-camera"
            label="Composition Image *"
            outlined
            background-color="white"
            @change="compositionImageHasChanged()"
            :error="errors.hasOwnProperty('composition_image')"
            :error-messages="errors['composition_image']"
          ></v-file-input>
          <v-textarea
            label="Kibble Size Description *"
            v-model="fields.kibble_size_text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('kibble_size_text')"
            :error-messages="errors['kibble_size_text']"
          ></v-textarea>
          <v-file-input
            v-model="fields.kibble_size_image"
            accept="image/png, image/jpeg"
            placeholder="Choose a Kibble Size Image"
            prepend-icon="mdi-camera"
            label="Kibble Size Image *"
            outlined
            background-color="white"
            @change="kibbleSizeImageHasChanged()"
            :error="errors.hasOwnProperty('kibble_size_image')"
            :error-messages="errors['kibble_size_image']"
          ></v-file-input>
          <v-textarea
            label="Nutritional Additives *"
            v-model="fields.nutritional_additives"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('nutritional_additives')"
            :error-messages="errors['nutritional_additives']"
          ></v-textarea>
          <v-card>
            <v-data-table
              :headers="constituentsTable.headers"
              :items="constituentsWithIndex"
              :disable-pagination="true"
              :hide-default-footer="true"
              no-data-text="There are no Analytical Constituents"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-subtitle-1"
                    >Analytical Constituents</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-btn color="accent" small @click="openConstituentsDialog()"
                    >Add Row</v-btn
                  >
                </v-toolbar>
                <v-alert
                  type="error"
                  dense
                  col="4"
                  rounded="0"
                  class="mb-0"
                  v-if="errors.hasOwnProperty('analytical_constituents')"
                  >{{ errors["analytical_constituents"].join(", ") }}</v-alert
                >
                <v-divider></v-divider>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      text
                      icon
                      color="blue"
                      v-on="on"
                      class="mr-2"
                      @click="openConstituentsDialog(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      text
                      icon
                      color="red"
                      v-on="on"
                      @click="deleteConstituent(item.index)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
        <v-overlay absolute v-if="productLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="product-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog
      scrollable
      v-model="constituentsDialog.open"
      max-width="400"
      @click:outside="resetConstituentsDialog()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{
            constituentsDialog.isEditing ? "Edit" : "New"
          }}
          Constituent</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            label="Constituent *"
            v-model="constituentsDialog.fields.text"
            type="text"
            outlined
          ></v-text-field>
          <v-text-field
            label="Value *"
            v-model="constituentsDialog.fields.value"
            type="text"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetConstituentsDialog()"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            type="submit"
            @click="saveConstituentsDialog()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      productLoading: false,
      isEditing: false,
      product: {},
      fields: {
        name: null,
        out_of_stock: false,
        tagline: null,
        summary: null,
        ga_code: null,
        image: null,
        image_has_changed: false,
        _2_bag_price: null,
        _12_bag_price: null,
        _24_bag_price: null,
        is_adult: false,
        is_puppy: false,
        composition_text: null,
        composition_image: null,
        composition_image_has_changed: false,
        kibble_size_text: null,
        kibble_size_image: null,
        kibble_size_image_has_changed: false,
        analytical_constituents: [],
        nutritional_additives: null,
      },
      constituentsTable: {
        headers: [
          { text: "Constituent", value: "text", sortable: false },
          { text: "Value", value: "value", sortable: false },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ],
      },
      constituentsDialog: {
        open: false,
        isEditing: false,
        constituent: {},
        fields: {
          text: null,
          value: null,
        },
      },
      errors: {},
    };
  },

  computed: {
    constituentsWithIndex() {
      return this.fields.analytical_constituents.map((constituent, index) => ({
        ...constituent,
        index,
      }));
    },
  },

  methods: {
    openForm(productId = null) {
      if (productId !== null) {
        this.isEditing = true;
        this.productLoading = true;

        this.$store
          .dispatch("lhl/products/loadProduct", {
            appId: this.$route.params.id,
            productId,
          })
          .then((product) => {
            this.product = product;
            this.fields.name = product.name;
            this.fields.tagline = product.tagline;
            this.fields.summary = product.summary;
            this.fields.ga_code = product.ga_code;
            this.fields.image = product.image
              ? new File([product.image], product.image)
              : null;
            this.fields.composition_image = product.composition_image
              ? new File([product.composition_image], product.composition_image)
              : null;
            this.fields.kibble_size_image = product.kibble_size_image
              ? new File([product.kibble_size_image], product.kibble_size_image)
              : null;
            this.fields._2_bag_price = product["2_bag_price"];
            this.fields._12_bag_price = product["12_bag_price"];
            this.fields._24_bag_price = product["24_bag_price"];
            this.fields.is_adult = product.is_adult;
            this.fields.is_puppy = product.is_puppy;
            this.fields.out_of_stock = product.out_of_stock;
            this.fields.composition_text = product.composition_text;
            this.fields.kibble_size_text = product.kibble_size_text;
            this.fields.analytical_constituents =
              product.analytical_constituents;
            this.fields.nutritional_additives = product.nutritional_additives;
            this.productLoading = false;
          });
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.product = {};
      this.errors = {};
      this.fields.name = null;
      this.fields.tagline = null;
      this.fields.summary = null;
      this.fields.ga_code = null;
      this.fields.image = null;
      this.fields.image_has_changed = false;
      this.fields.composition_image = null;
      this.fields.composition_image_has_changed = false;
      this.fields.kibble_size_image = null;
      this.fields.kibble_size_image_has_changed = false;
      this.fields._2_bag_price = null;
      this.fields._12_bag_price = null;
      this.fields._24_bag_price = null;
      this.fields.is_adult = false;
      this.fields.is_puppy = false;
      this.fields.out_of_stock = false;
      this.fields.composition_text = null;
      this.fields.kibble_size_text = null;
      this.fields.analytical_constituents = [];
      this.fields.nutritional_additives = null;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.productId = this.product.id;
      }

      this.$store
        .dispatch("lhl/products/saveProduct", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    imageHasChanged() {
      this.fields.image_has_changed = true;
    },

    compositionImageHasChanged() {
      this.fields.composition_image_has_changed = true;
    },

    kibbleSizeImageHasChanged() {
      this.fields.kibble_size_image_has_changed = true;
    },

    openConstituentsDialog(constituent = null) {
      if (constituent !== null) {
        this.constituentsDialog.isEditing = true;
        this.constituentsDialog.constituent = constituent;
        this.constituentsDialog.fields.text = constituent.text;
        this.constituentsDialog.fields.value = constituent.value;
      }

      this.constituentsDialog.open = true;
    },

    resetConstituentsDialog() {
      this.constituentsDialog.open = false;
      this.constituentsDialog.isEditing = false;
      this.constituentsDialog.constituent = {};
      this.constituentsDialog.fields.text = null;
      this.constituentsDialog.fields.value = null;
    },

    saveConstituentsDialog() {
      if (this.constituentsDialog.isEditing) {
        this.fields.analytical_constituents[
          this.constituentsDialog.constituent.index
        ].text = this.constituentsDialog.fields.text;
        this.fields.analytical_constituents[
          this.constituentsDialog.constituent.index
        ].value = this.constituentsDialog.fields.value;
      } else {
        this.fields.analytical_constituents.push({
          text: this.constituentsDialog.fields.text,
          value: this.constituentsDialog.fields.value,
        });
      }

      this.resetConstituentsDialog();
    },

    deleteConstituent(index) {
      this.fields.analytical_constituents.splice(index, 1);
    },
  },
};
</script>
